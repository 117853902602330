import React, { useState, useEffect } from "react";
import styled from "styled-components";
import abi2 from "../assets/abi/abi2.json";
import config from "../config/config";


const ethers = require("ethers");

const CardContainer = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  border-radius: 20px;
  padding: 20px;
  gap: 5px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 20px;
  border: 3px solid white;
`;

const Button = styled.button`
  background: transparent;
  border-radius: 50px;
  border: 3px solid #fff;
  padding: 5px 5px;
  text-transform: uppercase;
  background-color: #ffffff;
`;

const NFTCard = ({ tokenId, staked, balance, src, tier }) => {
  // useState provider null
  const [provider, setProvider] = useState(null);
  // useState contract null
  const [contract, setContract] = useState(null);
  // useState signer null
  const [signer, setSigner] = useState(null);

  // usestate currentTier 0
  const [currentTier, setCurrentTier] = useState(0);

  const updateEthers = async () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    let tempSigner = tempProvider.getSigner();
    let tempContract = new ethers.Contract(config.SCTAddress, abi2, tempSigner);
    setProvider(tempProvider);
    setSigner(tempSigner);
    setContract(tempContract);
  };

  const stake = async () => {
    await contract.stake(tokenId, { gasLimit: 3000000 }).then((tx) => {
      tx.wait().then((tx) => {
        window.location.reload();
      });
    });
  };

  const unstake = async () => {
    await contract.unstake(tokenId, { gasLimit: 4000000 }).then((tx) => {
      tx.wait().then((tx) => {
        window.location.reload();
      });
    });
  };

  const harvest = async () => {
    await contract.harvest(tokenId, { gasLimit: 4000000 }).then((tx) => {
      tx.wait().then((tx) => {
        window.location.reload();
        
      });
    });
  };

  const upgrade = async () => {
    await contract
      .upgradeTokenRewardTier(tokenId, { gasLimit: 3000000 })
      .then((tx) => {
        tx.wait().then(() => {
          window.location.reload();
        });
      });
  };

  useEffect(() => {
    updateEthers();
  }, []);

  if (staked) {
    return (
      <CardContainer>
        <Image src={src} />
        <h5>#{tokenId}</h5>
        <p>
          {balance} {config.symbol}
        </p>
        <p>LVL : {tier}</p>
        <Button onClick={unstake}>Unstake</Button>
        <Button onClick={harvest}>
          Claim {balance} {config.symbol}
        </Button>
        <Button onClick={upgrade}>Upgrade</Button>
      </CardContainer>
    );
  } else {
    return (
      <CardContainer>
        <Image src={src} />
        <h5>#{tokenId}</h5>
        <Button onClick={stake}>stake</Button>
      </CardContainer>
    );
  }
};

export default NFTCard;
