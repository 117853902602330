import React, { useState, useEffect } from "react";
import styled from "styled-components";
import banner from "../assets/images/banner.png";
import config from "../config/config";
import abi from "../assets/abi/abi.json";
import abi2 from "../assets/abi/abi2.json";
import NFTCard from "./NFTCard";

const ethers = require("ethers");

const StakingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Image = styled.div`
  background-image: url(${banner});
  background-size: contain;
  background-repeat: no-repeat;
  width: 500px;
  height: 400px;
`;

const StakingWrapper = styled.div`
  margin: 20px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const InfoPanel = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  min-height: 600px;
  border-radius: 20px;
  border: 5px solid black;
  padding: 20px;
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: red;
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff,
      0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TokenContainer = styled.div`
  width: 87%;
  margin: 0px 50px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  border: 5px solid black;
  padding: 20px;
  border-radius: 20px;
`;

const TokenGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  gap: 20px;
  color: red;
`;

const Staking = () => {
  // usestate isConnected false
  const [isConnected, setIsConnected] = useState(false);
  // usestate isStartStaking false
  const [isStartStaking, setIsStartStaking] = useState(false);
  // usestate defaultAccount null
  const [defaultAccount, setDefaultAccount] = useState(null);
  // usestate totalStaked 0
  const [totalStaked, setTotalStaked] = useState(0);
  // usestate isUserStaked false
  const [isUserStaked, setIsUserStaked] = useState(false);
  // usestate loading false
  const [loading, setLoading] = useState(false);
  // startedStaking false
  const [startedStaking, setStartedStaking] = useState(false);
  // usestate totalRewards 0
  const [totalRewards, setTotalRewards] = useState(0);
  // usestate sctBalance 0
  const [sctBalance, setSctBalance] = useState(0);

  // usestate has stakedNFTs false
  const [hasStakedNFTs, setHasStakedNFTs] = useState(false);
  // usestate has unstakedNFTs false
  const [hasUnstakedNFTs, setHasUnstakedNFTs] = useState(false);

  // usestate stakedNFTs []
  const [stakedNFTs, setStakedNFTs] = useState([]);
  // usestate unstakedNFTs []
  const [unstakedNFTs, setUnstakedNFTs] = useState([]);

  // usestate provider null
  const [provider, setProvider] = useState(null);
  // usestate contract null
  const [contract, setContract] = useState(null);
  // usestate signer null
  const [signer, setSigner] = useState(null);

  // usestate provider null
  const [provider2, setProvider2] = useState(null);
  // usestate contract null
  const [contract2, setContract2] = useState(null);
  // usestate signer null
  const [signer2, setSigner2] = useState(null);

  const selectedAccount = window.ethereum.selectedAddress;

  const connect = async () => {
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            setIsConnected(true);
            accountChangedHandler(account[0]);
          });
      }
    } else {
      setIsConnected(false);
    }
  };

  const accountChangedHandler = (account) => {
    setDefaultAccount(account);
    updateEthers();
  };

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);
    let tempProvider2 = new ethers.providers.Web3Provider(window.ethereum);
    setProvider2(tempProvider2);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);
    let tempSigner2 = tempProvider2.getSigner();
    setSigner2(tempSigner2);

    let tempContract = new ethers.Contract(config.NFTAddress, abi, tempSigner);
    setContract(tempContract);
    let tempContract2 = new ethers.Contract(
      config.SCTAddress,
      abi2,
      tempSigner2
    );
    setContract2(tempContract2);
  };

  const startStaking = async () => {
    let stakedTokens = [];
    let unstakedTokens = [];
    setLoading(true);

    await contract2.stakedNFTSByUser(defaultAccount).then((staked) => {
      for (let i = 0; i < staked.length; i++) {
        if (Number(staked[i].toString()) !== 0) {
          let claimable = 0;
          contract2.pendingRewards(staked[i].toString()).then((reward) => {
            let unrounded = ethers.utils.formatEther(reward.toString());
            claimable = parseFloat(unrounded).toFixed(2);
            contract2.currentTier(staked[i].toString()).then((tier) => {
              stakedTokens.push({
                tokenId: Number(staked[i].toString()),
                staked: true,
                balance: Number(claimable),
                src: config.baseURI + "/" + staked[i].toString() + ".png",
                tier: Number(tier.toString()),
              });
            });
          });
        }
      }
    });

    await contract2.balanceOf(defaultAccount).then((balance) => {
      let unrounded = ethers.utils.formatEther(balance.toString());
      let balance2 = parseFloat(unrounded).toFixed(2);
      setSctBalance(balance2);
    });

    let total = 0;
    await contract2.totalPendingRewards().then((result) => {
      total = result.toString();
    });
    setTotalRewards(parseFloat(ethers.utils.formatEther(total)).toFixed(2));

    await contract.walletOfOwner(defaultAccount).then((wallet) => {
      for (let i = 0; i < wallet.length; i++) {
        unstakedTokens.push({
          tokenId: Number(wallet[i].toString()),
          staked: false,
          balance: 0,
          src: config.baseURI + "/" + wallet[i].toString() + ".png",
        });
      }
    });

    unstakedTokens.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });
    stakedTokens.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });

    setStakedNFTs(stakedTokens);
    setUnstakedNFTs(unstakedTokens);
    console.log(stakedTokens);
    console.log(unstakedTokens);
    setLoading(false);
    setIsStartStaking(true);
    setStartedStaking(true);
  };

  const harvest = async () => {
    contract2.harvestBatch(defaultAccount).then((tx) => {
      tx.wait().then(() => {
        window.location.reload();
      });
    });
  };

  const stakeAll = async () => {
    contract2.stakeBatch().then((tx) => {
      tx.wait().then(() => {
        window.location.reload();
      });
    });
  };

  const unstakeAll = async () => {
   
    contract2.unstakeBatch().then((tx) => {
      tx.wait().then(() => {
        window.location.reload();
      });
    });
  };

  const approve = async () => {
    await contract.setApprovalForAll(contract2.address, true).then((tx) => {
      tx.wait().then(() => {
        console.log("Approved");
      });
    });
  };

  return (
    <StakingContainer>
      <Title>Welcome to Staking Portal</Title>
      <br />

      <h1>
        <center>WE ARE WORKING TO MIGRATE STAKING PORTAL TO NEW TOKEN!! MORE UPDATES ON DISCORD</center>{" "}
      </h1>
      <br />
      <h6>
        <center>ALL YOUR NFTs are safe</center>
      </h6>
      <br />
      <h6>
        <center>Unstake from old staking portal</center>
      </h6>
      
      <StakingWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "500px",
            height: "400px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={banner}
            alt="banner"
            style={{
              width: "100%",
              borderRadius: "20px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "500px",
            height: "316px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            border: "5px solid white",
            padding: "20px",
          }}
        >
          {!isConnected ? (
            <>
              <h1>Connect Your Wallet</h1> <br />
              <Button onClick={connect}>
                <h1>Connect</h1>
              </Button>
            </>
          ) : (
            <>
              {loading ? (
                <h1> Gathering info - loading </h1>
              ) : (
                <>
                  {startedStaking ? (
                    <>
                      <Splitter>
                        <h1>{stakedNFTs.length / 100}% Staked</h1>
                        <br />
                        <br />
                        <h1>{stakedNFTs.length} / 10000</h1>
                      </Splitter>
                      <Splitter>
                        <h1>My DOOD Balance :</h1>
                        <h1>{sctBalance}</h1>
                      </Splitter>
                      <br />
                      <p>
                        Connected to :{" "}
                        {defaultAccount.slice(0, 6) +
                          "..." +
                          defaultAccount.slice(-4)}
                      </p>{" "}
                      <br />
                      <h1
                        style={{
                          textAlign: "center",
                        }}
                      >
                        You have staked {stakedNFTs.length} DoodCats and <br />
                        {unstakedNFTs.length} DoodCats available to stake.
                      </h1>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        You need to approve first in order to stake your
                        DoodCats.
                      </p>
                      <Button onClick={approve}>
                        <h1>Approve</h1>
                      </Button>
                      <Button onClick={startStaking}>
                        <h1>Start Staking</h1>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </StakingWrapper>
      {isStartStaking ? (
        <StakingWrapper>
          {stakedNFTs.length === 0 ? (
            ""
          ) : (
            <InfoPanel>
              <h1>Staked NFTs</h1>
              <Splitter>
                <Button onClick={harvest}>Harvest All</Button>
                <h5>Total : {totalRewards} </h5>
                <Button onClick={unstakeAll}>Unstake All</Button>
              </Splitter>
              <TokenGrid>
                {stakedNFTs.map((token) => {
                  return (
                    <NFTCard
                      tokenId={token.tokenId}
                      staked={token.staked}
                      balance={token.balance}
                      src={token.src}
                      tier={token.tier}
                    />
                  );
                })}
              </TokenGrid>
              <Splitter>
                <Button onClick={harvest}>Harvest All</Button>
                <h5>Total : {totalRewards} </h5>
                <Button onClick={unstakeAll}>Unstake All</Button>
              </Splitter>
            </InfoPanel>
          )}
          {unstakedNFTs.length === 0 ? (
            ""
          ) : (
            <InfoPanel>
              <h1>Unstaked NFTs</h1>
             
              <TokenGrid>
                {unstakedNFTs.map((token) => {
                  return (
                    <NFTCard
                      tokenId={token.tokenId}
                      staked={token.staked}
                      balance={token.balance}
                      src={token.src}
                    />
                  );
                })}
              </TokenGrid>
              <Splitter
                style={{
                  justifyContent: "center",
                }}
              >
                <Button onClick={stakeAll}>Stake All</Button>
              </Splitter>
            </InfoPanel>
          )}
        </StakingWrapper>
      ) : (
        ""
      )}
    </StakingContainer>
  );
};

export default Staking;