import "./App.css";
import Navbar from "./components/Navbar";
import Staking from "./components/Staking";

function App() {
  return (
    <div style={{
      display: "flex",
      width: "100%",
      minHeight: "100vh",
      flexDirection: "column",
      backgroundColor: "#F1C0E8",
    }}>
      <Navbar />
      <Staking />
    </div>
  );
}

export default App;
